<template>
  <div id="page-selects">
    <v-container grid-list-xl fluid class="pa-2">
      <v-layout row wrap>
        <v-flex lg12 xs12>
          <v-widget :title="titleString">
            <div slot="widget-content">
              <v-form ref="form">
                <v-layout row wrap>
                  <!-- <v-flex md5 pl-5 pr-5 class="text-xs-center">
                    <v-text-field outlined label="File Name" v-model="filename"></v-text-field>
                    <v-text-field outlined label="Version" v-model="version"></v-text-field>
                  </v-flex> -->
                  <v-flex style="max-width: 10vw">
                    <input type="file" id="file" ref="file" style="display: none;" @change="filesUpload()"/>
                    <v-btn flat outline color="blue darken-4" @click="$refs.file.click()">
                      <v-icon>attachment</v-icon> Select File
                    </v-btn>
                  </v-flex>
                  <v-flex md2 v-if="nameFile"><v-chip>{{nameFile}}</v-chip></v-flex>
                  <v-flex md6>
                    <v-btn flat outline color="green" :disabled="!valid" class="white--text btn-save" @click="filesTest()">
                      <v-icon dark class="grenn--text">save</v-icon>Save
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex md6>
                    {{fileId}}
                  </v-flex>
                </v-layout>
              </v-form>
            </div>
          </v-widget>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  // import 'babel-polyfill'
  import VWidget from '@/components/VWidget'
  import axios from 'axios'
  export default {
    components: {
      VWidget
    },
    data () {
      return {
        titleString: 'Upload to MongoDB',
        filename: '',
        version: '',
        valid: true,
        nameFile: '',
        fileId: '',
      }
    },
    methods: {
      filesUpload () {
        const uploadedFiles = this.$refs.file.files
        this.nameFile = uploadedFiles[0].name
      },
      filesTest () {
        // if (this.filename !== '' && this.version !== '') {
        let formData = new FormData()
        formData.append('file', this.$refs.file.files[0])
        axios.post(process.env.VUE_APP_API + '/pymongoDB/uploadFileMongo',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(response => {
          if (response.data.isSuccess) {
            console.log(response.data, '===response.data===')
            this.$swal({type: 'success', title: response.data.data.toString()})
            this.fileId = response.data.data
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        // }
      },
    }
  }
</script>
